<template>
  <div class="home">
    <div class="home-landing">
      <Landing/>
    </div>
    <div class="home-paralax">
      <div class="paralax">
        <div class="landing-window"></div>
        <!-- <svg id="wave" style="transform:rotate(0deg); transition: 0.3s; margin-bottom: -5px" viewBox="0 0 1440 250" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stop-color="#06066B" offset="0%"></stop><stop stop-color="rgba(1, 23, 128, 1)" offset="100%"></stop></linearGradient></defs><path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-0)" d="M0,50L21.8,41.7C43.6,33,87,17,131,12.5C174.5,8,218,17,262,45.8C305.5,75,349,125,393,137.5C436.4,150,480,125,524,100C567.3,75,611,50,655,33.3C698.2,17,742,8,785,33.3C829.1,58,873,117,916,150C960,183,1004,192,1047,179.2C1090.9,167,1135,133,1178,116.7C1221.8,100,1265,100,1309,104.2C1352.7,108,1396,117,1440,104.2C1483.6,92,1527,58,1571,58.3C1614.5,58,1658,92,1702,108.3C1745.5,125,1789,125,1833,125C1876.4,125,1920,125,1964,125C2007.3,125,2051,125,2095,108.3C2138.2,92,2182,58,2225,62.5C2269.1,67,2313,108,2356,108.3C2400,108,2444,67,2487,45.8C2530.9,25,2575,25,2618,20.8C2661.8,17,2705,8,2749,41.7C2792.7,75,2836,150,2880,187.5C2923.6,225,2967,225,3011,200C3054.5,175,3098,125,3120,100L3141.8,75L3141.8,250L3120,250C3098.2,250,3055,250,3011,250C2967.3,250,2924,250,2880,250C2836.4,250,2793,250,2749,250C2705.5,250,2662,250,2618,250C2574.5,250,2531,250,2487,250C2443.6,250,2400,250,2356,250C2312.7,250,2269,250,2225,250C2181.8,250,2138,250,2095,250C2050.9,250,2007,250,1964,250C1920,250,1876,250,1833,250C1789.1,250,1745,250,1702,250C1658.2,250,1615,250,1571,250C1527.3,250,1484,250,1440,250C1396.4,250,1353,250,1309,250C1265.5,250,1222,250,1178,250C1134.5,250,1091,250,1047,250C1003.6,250,960,250,916,250C872.7,250,829,250,785,250C741.8,250,698,250,655,250C610.9,250,567,250,524,250C480,250,436,250,393,250C349.1,250,305,250,262,250C218.2,250,175,250,131,250C87.3,250,44,250,22,250L0,250Z"></path></svg> -->
        <AnimatedWave color="#06066B"/>
        <Presentation/>
        <Projects/>
        <References/>
        <footer>
          <div class="credits">Louan Bengmah © 2022</div>
          <div class="contacts">
            <a href="https://twitter.com/louanben" target="_blank" >twitter</a>
            <a href="https://www.linkedin.com/in/louan-bengmah-75196817b/" target="_blank" >linkedin</a>
            <a href="tel:+33781600535" target="_blank" >phone</a>
            <a href="mailto:louanben.pro@gmail.com " target="_blank">email</a>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
  import Landing from '../Landing.vue'
  import Presentation from '../Presentation.vue'
  import Projects from '../Projects.vue'
  import References from '../References.vue'
  import AnimatedWave from '../utils/AnimatedWave.vue'

  export default {
    name: 'Home',
    components: {
      Landing,
      Presentation,
      Projects,
      References,
      AnimatedWave,
    },
  }
</script>

<style lang="sass">
$background-color: #282B5F
$secondary-background-color: #06066B
$primary: #FB9C47

.home
  //background: $background-color
  max-width: 100vw
  //min-height: 100vh
  //scroll-snap-type: y mandatory
  *
    color: white
    font-family: 'Outfit', sans-serif
  h1, h2, h3, p, span
    margin: 0
  .home-landing
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    z-index: -99
  .home-paralax
    overflow: auto
    overflow-x: hidden
    height: auto
    .paralax
      width: 100vw
      height: 100vh
      .landing-window
        height: 55vh
        @media (max-width: 768px)
          height: 75vh
      .animated-wave
        margin-bottom: -150px
        position: relative
        z-index: -1
        @media (max-width: 768px)
          margin-bottom: -10px
  footer
    background: $secondary-background-color
    padding: 24px
    padding-top: 48px
    display: flex
    justify-content: center
    align-items: center
    font-size: 0.7em
    .contacts
      margin-left: 2em
      a
        text-decoration: none
        margin: 0 0.5em
        &:hover
          text-decoration: underline
</style>
