<template>
    <section class="presentation">
      <div class="presentation-content">
        <div class="title">
          <h2>Qui suis-je ?</h2>
          <p class="title-complement">c'est moi</p>
        </div>
        <div class="presentation-cards">
          <Card class="presentation-intro">
            <h3 class="with-emoji">🧑🏽 En quelques lignes</h3>
            <p>Je suis <strong>Louan Bengmah</strong>, un développeur web de 21 ans. Je touche un peu à tout ce qu'on peut faire sur Internet (c'est-à-dire beaucoup de choses).</p>
            <p>J'utilise mes compétences en <strong>développement web</strong> pour créer des sites et des programmes utiles, esthétiques, ou fun, à destination des utilisateur•ices.</p>
            <p>Je suis très présent sur les réseaux sociaux, notamment sur Twitter et Twitch, où j'aime partager <strong>mes créations</strong> et mes pensées.</p>
          </Card>
          <div class="cards-container">
            <Card class="presentation-socials">
              <h3 class="with-emoji">🔗 Où me trouver ?</h3>
              <div class="socials">
                <a class="twitter" href="https://twitter.com/louanben" target="_blank">
                  <img src="~@/assets/icons/socials/twitter.svg" alt="Twitter">
                </a>
                <a class="instagram" href="https://instagram.com/louan.ben" target="_blank">
                  <img src="~@/assets/icons/socials/instagram.svg" alt="Instagram">
                </a>
                <a class="linkedin" href="https://www.linkedin.com/in/louan-bengmah-75196817b/" target="_blank">
                  <img src="~@/assets/icons/socials/linkedin.svg" alt="LinkedIn">
                </a>
                <a class="github" href="https://www.github.com/LouanBen" target="_blank">
                  <img src="~@/assets/icons/socials/github.svg" alt="GitHub">
                </a>
                <a class="twitch" href="https://twitch.tv/louanben" target="_blank">
                  <img src="~@/assets/icons/socials/twitch.svg" alt="Twitch">
                </a>
              </div>
            </Card>
            <a class="presentation-cv" href="LouanBengmah-CV.pdf" target="_blank">
              <Card>
                <h3 class="with-emoji">📄 Découvrir mon CV</h3>
                <img class="cta-arrow" src="../assets/icons/cta-arrow-black.svg" alt="Découvrir mon CV">
              </Card>
            </a>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import Card from './utils/Card.vue';

export default {
  name: 'Presentation',
  components: {
      Card,
  },
}
</script>

<style lang="sass" scoped>
$background-color: #282B5F
$secondary-background-color: #06066B
$primary: #FB9C47

.presentation
  width: 100vw
  display: flex
  scroll-snap-align: start
  scroll-snap-stop: normal
  background: $secondary-background-color
  padding-bottom: 96px
  .presentation-content
    max-width: 1200px
    margin: 0 auto
    padding: 50px 0
    .title
      position: relative
      width: 528px
      margin: auto
      h2
        color: $primary
        font-size: 6em
        display: inline
        position: relative
        z-index: 2
        &::before
          content: "Qui suis-je ?"
          font-family: 'Outfit', sans-serif
          color: transparent
          font-size: 1em
          display: inline
          -webkit-text-stroke: 0.2em $secondary-background-color
          color: transparent
          position: absolute
          top: -5px
          left: 0
          z-index: -1
          -webkit-text-decoration: white solid underline
          text-decoration: white solid underline
          text-decoration-thickness: 3.5px
          text-underline-offset: 17px
          width: 101%
      &-complement
        position: absolute
        background: $secondary-background-color
        padding: 0 0 0 10px
        bottom: 0
        right: 0
        z-index: 3

    p
      max-width: 800px
      margin-top: 4em
    .presentation-cards
      display: flex
      margin-top: 4em
      &>*
        margin: 0 20px
        width: 50%
      .cards-container
        display: flex
        flex-direction: column
        justify-content: space-between
      .card
        //width: 100%
        h3
          color: black
          margin-bottom: 1em
        p
          color: black
          margin: 0
          margin-bottom: 1em
          max-width: 100%
          strong
            color: black
        .socials
          display: flex
          flex-wrap: wrap
          a
            margin: 0.25em 1em
            display: block
            width: 48px
            height: 48px
            border-radius: 8px
            overflow: hidden
            img
              width: 100%
            &.twitter
              box-shadow: 0px 2.5px 0px 0px #1D76D3
            &.instagram
              box-shadow: 0px 2.5px 0px 0px #E1306C
            &.linkedin
              box-shadow: 0px 2.5px 0px 0px #00496D
            &.github
              box-shadow: 0px 2.5px 0px 0px #3d3d3d
            &.twitch
              box-shadow: 0px 2.5px 0px 0px #6441A5
      .presentation-cv
        .card
          display: flex
          justify-content: space-between
          h3
            margin-bottom: 0
          .cta-arrow
            width: 24px
  @media (max-width: 768px)
    padding-top: 10vh
    .presentation-content
      padding: 0 24px
      .title
          width: 100%
          h2
            font-size: 3rem
            text-align: center
            width: 100%
            display: block
            &::before
              width: 100%
              font-size: 3rem
              -webkit-text-stroke: 0.3em $secondary-background-color
              text-underline-offset: 10px
          &-complement
            display: none
            font-size: 1rem
            bottom: -0.5rem
            line-height: 0.5rem
      .presentation-cards
        flex-direction: column
        &>*
          width: 100%
          margin: 0
        .card
          width: 100%
          margin: 0
          .socials
            a
              margin: 0.5em
              width: 32px
              height: 32px
        .card:not(:last-child)
          margin-bottom: 2em
</style>